<template>
  <div>
    <v-dialog
      slot="body"  transition="dialog-bottom-transition" v-model="show" 
      @click:outside="$emit('update:show', false)" :max-width="width != null ? width : ''"
    >
      <template v-slot:default="">
        <v-card>
          <slot name="body"></slot>
          <v-card-actions class="justify-end">
            <v-btn text class="whiteButton" @click="$emit('update:show', false)">بستن</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {

    return {
      autoUpdate: true,
      isUpdating: false,
    }
  },
  props:['show','width'],
  watch: {
    isUpdating (val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 5000)
      }
      close()
      {
        document.getElementById('satrex').click()
      }
    }
  },

  methods: {

  }
}
</script>
